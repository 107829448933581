<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1>Create Employee Dispute</h1>
                    <router-link class="btn" to="/dispute/employee/group/show" active-class="active" style="float: right;">Back</router-link>
                </div>
            </div>
            <form class="row" @submit.prevent="createDispute" style="margin-top: 40px;">
                <div class="col-3">
                    <div class="form-group">
                                <div class="form-group">
                                    <label for="">Notification of dispute via:</label>
                                    <select class="custom-select" v-model="natificationType">
                                        <option
                                                v-for="notification in activeLanguage.notification"
                                                :selected="notification === [activeLanguage.notification]"
                                        >
                                            {{notification}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="">Dispute Category</label>
                        <select class="custom-select" v-model="disputeCategory">
                            <option
                                :value="category"
                                v-for="category in activeLanguage.categories"
                                :selected="category === [activeLanguage.categories]"
                            >
                                {{category}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-3">
                    <div class="form-group">
                        <label for="">Client Email</label>
                        <input v-model="orderClientInfo.email" type="text" class="form-control" placeholder="Client Email">
                    </div>
                </div>
                <div class="col-9">
                    <div class="form-group">
                        <label for="">First dispute message</label>
                        <textarea style="resize: none;" class="form-control" v-model="disputeMes" placeholder="First dispute message"></textarea>
                    </div>
                </div>
                <div class="col-9" style="padding-top: 4px;">
                    <div class="row">
                        <div class="col-4 dc">
                            <b-form-file
                                @change="fileUpload($event)"
                                ref="fileInput"
                                placeholder="Attachment"
                            >
                            </b-form-file>
                        </div>
                        <div class="col-6"></div>
                        <div class="col-1">
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
	import {mapGetters} from  "vuex"

	export default {
		name: "DisputeEmployeeCreateDispute",
		data() {
			return {
				disputeName: '',
				disputeCategory: '1',
				natificationType: '2',
				disputeMes: '',
                file: {
				    name: '',
                    attachment: null
                },
				orderClientInfo: {},
				orderData: {}
			}
		},
		computed: {
			...mapGetters({
                activeLanguage: 'getDisputeCreateLanguage'
			})
		},
        watch: {
			'activeLanguage': function (data) {
				this.disputeCategory = data.categories[0]
				this.natificationType = data.notification[0]
            }
        },
        mounted() {
			this.getData()
	        this.disputeCategory = this.activeLanguage.categories[0]
			this.natificationType = this.activeLanguage.notification[0]
        },
		methods: {
			fileUpload(e) {
				if (e.target.files[0].size > 25000000) {
					this.$refs.fileInput.reset()
					EventBus.$emit('showAlertModal', 'File size shouldn\'t be more than 25 MB')
					return false
				}

				if (e.target.files[0]) {
					if (['png', 'jpg', 'gif', 'jpeg', 'svg', 'pdf', 'xls', 'doc', 'docx', 'csv', 'txt'].includes(e.target.files[0].name.split('.').pop().toLowerCase())) {
						let reader = new FileReader()
						reader.readAsDataURL(e.target.files[0])
						reader.onload = () => {
							this.file.name = e.target.files[0].name
							this.file.attachment = reader.result.slice(reader.result.indexOf('base64') + 7, reader.result.length)
						}
					}
					else {
						this.$refs.fileInput.reset()
                    }
                } else {
					this.file = {
						name: '',
                        attachment: null
					}
                }
            },
			getData() {
				disputeAxios
                    .get('/api/employee-dispute/group-view/' + this.$route.params.groupId)
                    .then(({ data }) => {
	                    this.getOrderInfo(data.invoiceNr)
	                    this.getOrderClientInfo(data.invoiceNr)
                    })
                    .catch(err => console.error(err))
			},
            getOrderClientInfo(inv) {
	            billinkAxios
	            	.get(`app/order-details/${inv}/client`)
	            	.then(({ data }) => {
						this.orderClientInfo = data	
					})
	            	.catch(err => console.error(err))
			},
			validEmail (val) {
				let regExp = /(@pay.nl)/g
				return regExp.test(val)
			},
            createDispute() {
		        if (this.disputeCategory === 'Choose a dispute category' || this.disputeCategory === 'Kies een geschilcategorie') {
			        EventBus.$emit('showAlertModal', 'Please select dispute category')
			        return false
		        }

                let data = {
	                customerEmail: this.orderClientInfo.customer_email,
	                customerName: `${this.orderClientInfo.customer_first_name} ${this.orderClientInfo.customer_last_name}`,
	                clientBillinkId: this.orderClientInfo.id,
	                clientName: this.orderClientInfo.username || `${this.orderClientInfo.first_name} ${this.orderClientInfo.last_name}`,
	                clientEmail: this.orderClientInfo.email,
	                disputeGroupId: this.$route.params.groupId,
	                category: this.disputeCategory,
	                locale: this.activeLanguageName,
	                notificationMode: this.natificationType === 'Email' ? 'email' : 'sms'
				}
				
				if (!!this.validEmail(this.orderClientInfo.email)) {
					let confirm = window.confirm(this.activeLanguage.confirm.text)
					if (!!confirm) {
						disputeAxios
							.post('/api/employee-dispute/create', data)
							.then(({data}) => {
								if (data.code === 2008) {
									if (typeof data.message === 'string') {
										EventBus.$emit('showAlertModal', data.message)
									} else {
											let val = ''
											for (let err in data.message.errors) {
												switch (err) {
													case 'clientEmail':
														val += 'Client Email' + '\n'
														break;
													case 'clientName':
														val += 'Client Name' + '\n'
														break;
													case 'clientPhone':
														val += 'Client Phone' + '\n'
														break;
													case 'clientCompanyName':
														val += 'Client Company Name' + '\n'
														break;
													case 'customerEmail':
														val += 'Customer Email' + '\n'
														break;
													case 'customerName':
														val += 'Customer Name' + '\n'
														break;
													case 'customerPhone':
														val += 'Customer Phone' + '\n'
														break;
													case 'customerPostCodeAndCity':
														val += 'Customer Post Code and City' + '\n'
														break;
													case 'invoice':
														val += 'Invoice number' + '\n'
														break;
													default:
														break;
												}
										}
											EventBus.$emit('showAlertModal', 'Following data is missing from the order: \n' + val)
										}
									} else {
										if (!!this.disputeMes.length || !!this.file.name.length) {
											this.sendMessage(data)
										} else {
											this.$router.push(`/order/${this.orderData.invoice_number}?openDispute=true&id=${this.$route.params.groupId}`);
										}

										if (!this.orderData.pause.is_pause) { this.setOrderOnPause() }

									}
								})
								.catch(err => console.error(err))

								let syncParams = {
										type: "create_dispute",
										employee_dispute_id: this.$route.params.groupId,
										invoice: this.orderData.invoice_number
									}

									billinkAxios
										.post('/app/employeelog', syncParams)
										.then()
										.catch(err => console.error(err))
					}
				} else {
					disputeAxios
							.post('/api/employee-dispute/create', data)
							.then(({data}) => {
								if (data.code === 2008) {
									if (typeof data.message === 'string') {
										EventBus.$emit('showAlertModal', data.message)
									} else {
											let val = ''
											for (let err in data.message.errors) {
												switch (err) {
													case 'clientEmail':
														val += 'Client Email' + '\n'
														break;
													case 'clientName':
														val += 'Client Name' + '\n'
														break;
													case 'clientPhone':
														val += 'Client Phone' + '\n'
														break;
													case 'clientCompanyName':
														val += 'Client Company Name' + '\n'
														break;
													case 'customerEmail':
														val += 'Customer Email' + '\n'
														break;
													case 'customerName':
														val += 'Customer Name' + '\n'
														break;
													case 'customerPhone':
														val += 'Customer Phone' + '\n'
														break;
													case 'customerPostCodeAndCity':
														val += 'Customer Post Code and City' + '\n'
														break;
													case 'invoice':
														val += 'Invoice number' + '\n'
														break;
													default:
														break;
												}
										}
											EventBus.$emit('showAlertModal', 'Following data is missing from the order: \n' + val)
										}
									} else {
										if (!!this.disputeMes.length || !!this.file.name.length) {
											this.sendMessage(data)
										} else {
											this.$router.push(`/order/${this.orderData.invoice_number}?openDispute=true&id=${this.$route.params.groupId}`);
										}

										if (!this.orderData.pause.is_pause) { this.setOrderOnPause() }

									}

									let syncParams = {
										type: "create_dispute",
										employee_dispute_id: this.$route.params.groupId,
										invoice: this.orderData.invoice_number
									}

									billinkAxios
										.post('/app/employeelog', syncParams)
										.then()
										.catch(err => console.error(err))
								})
								.catch(err => console.error(err))
				}
            },
			sendMessage(dispute) {
				let data = {
					dispute: dispute.id,
					message: this.disputeMes,
					fileName: this.file.name,
					file: this.file.attachment
				}

				disputeAxios
					.post(`/api/employee-dispute/content/add`, data)
					.then(({ data }) => {
						if ( data.code === 2008 ) {
							EventBus.$emit('showAlertModal', 'You have to setup EmailTemplateVariables')
						} else if (data.code === 2024) {
							EventBus.$emit('showAlertModal', data.message)
						} else {
							this.$router.push(`/order/${this.orderData.invoice_number}?openDispute=true&id=${this.$route.params.groupId}`);
						}
					})
					.catch(err => console.error(err))
			},

			getOrderInfo(orderNum) {
				billinkAxios
					.get(`app/order-details/${orderNum}`)
					.then(({data}) => this.orderData = data)
					.catch(err => console.error(err))
			},
			setOrderOnPause() {
				let data = {
					order_id: this.orderData.id,
					manual: -1
				}

				billinkAxios
					.post('app/order/set-on-hold', data)
					.then(({ data }) => {})
					.catch(err => console.error(err))
			},
		}
	}
</script>

<style>
    .dc .custom-file-label::after {
        content: 'Upload';
    }
</style>